@media screen and (min-width: 992px) {
    html {
        font-size: 16px;
        
        body {
            letter-spacing: -0.09px;
            .feature {
                .group {
                    &.reverse { flex-direction: row-reverse; }
                    padding-bottom: 27px;
                    h1 {
                        letter-spacing: -1.26px;
                    }
                    p {
                        letter-spacing: -0.08px;
                    }
                    .button-container {
                        padding-left: 0;
                        button {
                            margin-left: 11vw;
                            width: 38.2rem;    
                        }
                    }
                }
            }
            .home {
                background-repeat: no-repeat;
                background-position: top center;
                background-size: contain;
                .small-title {
                    letter-spacing: 0.61px;
                }
                .hero {
                    h1 {
                        letter-spacing: -1.65px;
                    }
                    p {
                        font-size: 18px;
                        font-weight: 600;
                    }
                }
                .carousel {
                    margin-top: 4px;
                    line-height: 2.6rem;
                    h1 {
                        font-size: 4.8rem;
                        letter-spacing: -1.44px;
                    }
                    .scroll-container {
                        .scroll-content {
                            .item {
                                margin-left: 4px;
                                width: 70vw;
                                height: 57.6rem;
                                padding-left: 5.2rem;
                                h1 {
                                    line-height: 5.8rem;
                                }
                                .text {
                                    height: 15rem;
                                    p { width: 64rem; }
                                }
                            }
                        }
                    }
                }
                .testimonials {
                    line-height: 3.3rem;
                    h2 {
                        line-height: 5.8rem;
                        letter-spacing: -1.56px;
                    }
                    .testimonial-list {
                        display: flex;
                        > * {
                            flex: 1;
                        }
                        p {
                            font-size: 18px;
                        }
                    }
                    .testimonial-image {
                        border: 10px solid #FFFFFF;
                        width: 182px;
                        height: 182px;
                    }
                }
                .benefits {
                    padding-top: 10.5rem;
                    padding-bottom: 12.8rem;
                    h1 {
                        font-size: 5.2rem;
                        line-height: 5.8rem;
                    }
                    .intro {
                        margin: 0 auto 6rem;
                        width: 80%;
                        text-align: center;
                    }
                    .cards {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        .card {
                            margin: 1.5rem 0 0 1.5rem;
                            width: 25.8rem;
                            height: 21.2rem;
                            padding-top: 0;
                            padding-bottom: 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            img { width: 6.5rem; }
                            .symbol {
                                flex: 1;
                                display: flex;
                                align-items: center;
                                padding-top: 2rem;
                            }
                            .text {
                                margin-left: 0;
                                display: flex;
                                flex-direction: column;
                                flex: 1;
                                align-items: flex-start;
                                text-align: center;
                            }
                            .description {
                                text-align: center;
                            }
                        }
                    }
                }
                .careers {
                    text-align: center;
                    .cta {
                        font-size: 1.6rem;
                    }
                    .card-list {
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        .card {
                            margin: 2.2rem 0 0 2rem;
                            width: 44rem;
                            height: 14rem;
                            text-align: left;
                            .title {
                                font-size: 2rem;
                                font-weight: $weight-extra-bold;
                                line-height: 3rem;
                            }
                            .employment-type {
                                font-size: 1.8rem;
                            }
                            .compliment {
                                font-size: 2rem;
                            }
                        }
                    }
                    .contact {
                        margin-top: 3rem;
                        font-size: 1.4rem;
                        a {
                            display: inline;
                        }
                    }
                }
                .full-width-image {
                    height: 869px;
                }
            }
        }
        .blog-intro {
            -webkit-line-clamp: 5;
        }
    }
}