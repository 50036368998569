.image-component {
  
  &__image-container {
    position: relative;
    @media (min-width: $screen-lg) {
      margin: 0;
    }
  }
  &__image-mobile {
    max-width: none;
    width: 100%;
  }
  &__label {
    position: absolute;
    font-size: 12px;
    &--mobile {
      @media (min-width: $screen-lg) {
        display: none;
      }
    }
    &--desktop {
      display: none;
      @media (min-width: $screen-lg) {
        display: block;
      }
    }
  }

}