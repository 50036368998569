.video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.56);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;

    &__inner {
        width: 90vw;
        height: 90vh;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        max-width: 560px;

        @media (min-width: 960px) {
            width: 50vw;
            height: 50vh;
            max-width: none;
        }
    }

    &__player {
       width: 100%;
    }
}