@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600;700;800&display=swap');
@import url("https://cdn.plyr.io/3.7.8/plyr.css");

$bright-orange: #f96302;
$off-white: #f2f2f2;
$weight-medium: 500;
$weight-semi-bold: 600;
$weight-bold: 700;
$weight-extra-bold: 800;

$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

html {
    font-size: 16px;
    body {
        font-family: 'Open Sans', sans;
        color: #1c2833;
        h1 {
            font-weight: $weight-bold;
        }
        .home {
            background-position: center;
            background-size: cover;
            .standard-button {
                margin-top: 2.6rem;
                width: 100%;
                border-radius: 3.2rem;
                background-color: $bright-orange;
                padding: 1.5rem 0;
                font-weight: $weight-bold;
                color: white;
            }
            .small-title {
                font-weight: $weight-bold;
                line-height: 2.4rem;
                text-transform: uppercase;
                color: $bright-orange;
            }
            .hero {
                h1 {
                    margin-bottom: 1.4rem;    
                }
            }
        }
        .testimonials {
            h2 {
                margin-bottom: 2.2rem;
                font-weight: $weight-bold;
                text-align: center;
                letter-spacing: -0.09px;
            }
        }
        .standard-button {
            margin-top: 2.6rem;
            width: 100%;
            border-radius: 3.2rem;
            background-color: $bright-orange;
            padding: 1.5rem 0;
            font-weight: $weight-bold;
            color: white;
        }
        .benefits {
            .cards {
                color: #004361;
                .card {
                    margin-top: 0.8rem;
                    .text { margin-left: 2.5rem; text-align: left; flex: 1; }
                    .description { 
                        font-size: 13px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.31;
                        letter-spacing: -0.07px;
                        text-align: left;
                        color: #004361;
                        margin-bottom: 1rem;
                        margin-top: 1rem;
                        flex: 0;
                        }
                }
            }
            .feature {
                .group {
                    h1 {
                        letter-spacing: -0.78px;   
                    }
                    p {
                        white-space: pre-wrap;
                    }
                }
            }
            .testimonials {
                h2 {
                    margin-bottom: 2.2rem;
                    font-weight: $weight-bold;
                    text-align: center;
                    letter-spacing: -0.09px;
                }
            }
            .cta {
                margin-top: 2.8rem;
                text-align: center;
            }
            .careers {
                h1 {
                    text-align: center;
                }
                .intro {
                    text-align: center;
                }
                .cta {
                    margin-top: 2.8rem;
                    text-align: center;
                }
                .card-list {
                    .card {
                        margin-top: 1.2rem;
                        min-height: 10rem;
                        border-radius: 0.8rem;
                        background-color: #01364d;
                        padding: 1.2rem 1.6rem;
                        display: flex;
                        color: white;
                        flex-direction: column;
                        justify-content: space-between;
                        line-height: 2rem;
                        .title {
                            font-weight: $weight-bold;
                            letter-spacing: -0.09px;
                        }
                        .employment-type {
                            font-size: 1.5rem;
                            letter-spacing: -0.08px;
                        }
                        .compliment {
                            margin-top: 0.5rem;
                            font-size: 1.6rem;
                            font-weight: $weight-bold;
                            letter-spacing: -0.06px;
                            color: $bright-orange;
                        }
                    }
                    .employment-type {
                        font-size: 1.5rem;
                        letter-spacing: -0.08px;
                    }
                    .compliment {
                        margin-top: 0.5rem;
                        font-size: 1.6rem;
                        font-weight: $weight-bold;
                        letter-spacing: -0.06px;
                        color: $bright-orange;
                    }
                }
            }
            .full-width-image {
                height: 306px;
            }
        }
        .footer {
            background-color: #0e1922;
            .social-icons {
                display: flex;
                justify-content: center;
            }
            .links {
                ul {
                    display: flex;
                    justify-content: center;
                    line-height: 1.6rem;
                }
            }
            .copyright {
                text-align: center;
            }
        }
    }
}

@import 'tablet.scss';
@import 'desktop.scss';

.arrow-left {
    background-image: url(/images/arrow-right.svg);
    transform: scaleX(-1);

    &:hover, &:focus {
        background-image: url(/images/arrow-right-orange.svg);
    }
}

.arrow-right {
    background-image: url(/images/arrow-right.svg);

    &:hover, &:focus {
        background-image: url(/images/arrow-right-orange.svg);
    }
}

.blog-entry-content {
    ul {
        list-style: disc;
        margin-left: 20px;
    }
}


.blog .block-img {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 15px;
    display: inline-block;
    vertical-align: top;
}

@import './styles/video-carousel';
@import './styles/image-component';
@import './styles/carousel';
@import './styles/video-modal';
@import './styles/values-component';
@import './styles/blog-slice';
