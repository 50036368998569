.values-component {

  padding: 72px 22px 0;

  &__heading {
    font-size: 22px;
    letter-spacing: -0.66px;
    line-height: 30px;
    border-bottom: solid 2px $bright-orange;
    padding-bottom: 20px;
    margin-bottom: 18px;
    @media (min-width: $screen-md) {
      font-size: 28px;
      letter-spacing: 0px;
      border-bottom: none;
      line-height: 36px;
      width: 760px;
      text-align: center;
      margin: 0 auto;
    }
  }
  &__intro {
    padding-right: 24px;
    margin-bottom: 25px;
    @media (min-width: $screen-md) {
      text-align: center;
    }
  }
  &__select-prompt {
    color: #757C83;
    @media (min-width: $screen-md) {
      text-align: center;
    }
  }
  &__value-container {
    padding-top: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    column-gap: 4px;
    @media (min-width: $screen-md) {
      flex-direction: row;
      justify-content: center;
      width: 1230px;
      margin: 0 auto;
      padding: 40px 0px;
    }
  }
  &__value-scroll-container {
    @media (min-width: $screen-md) {
      overflow-x: scroll;
    }
  }
  &__value-item-wrapper {
    width: 302px;
    height: 368px;
    position: relative;
    cursor: pointer;
  }
  &__value-item {
    border: 2px solid $bright-orange;
    border-radius: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 20px 24px;
    display: flex;
    flex-direction: column;
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    position: relative;
    &--selected {
      width: 112%;
      height: 112%;
      box-shadow: 0px 10px 16px #00000067;
      z-index: 10;
    }
  }
  &__value-item-shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
    border-radius: 15px;
  }
  &__value-item-number {
    color: white;
    opacity: 0.4;
    font-size: 80px;
  }
  &__value-item-spacer {
    flex: 1;
    transition: flex 0.4s ease-in-out;
    &--collapse {
      flex: 0;
    }
  }
  &__value-item-title {
    color: white;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 10px;
    width: 272px;
    height: 74px;
  }
  &__value-item-detail {
    color: white;
    font-size: 16px;
    letter-spacing: -0.08px;
    margin-bottom: 32px;
    overflow-y: hidden;
    height: 0;
    transition: height 0.4s ease-in-out;
    &--show {
      height: 120px;
    }
  }
  &__value-item-more-prompt {
    color: #757C83;
    font-size: 14px;
    letter-spacing: -0.07px;
    text-decoration: underline;
  }

}