.carousel {
  padding-top: 40px;
  @media (min-width: $screen-lg) {
    display: flex;
  }
  &__first-pane {
    @media (min-width: $screen-lg) {
      flex: 1;
    }
  }
  &__text {
    @media (min-width: $screen-lg) {
      margin-bottom: 32px;
    }
  }
  &__supporting-image {
    display: none;
    margin: 0 auto;
    @media (min-width: $screen-lg) {
      display: block;
    }
  }
  .scroll-container {
      scroll-snap-type: x mandatory;
      @media (min-width: $screen-lg) {
        flex: 1;
        margin-top: 0;
      }
      .scroll-content {
        width: fit-content;
        @media (min-width: $screen-lg) {
          display: block;
        }
        .item {
            background-position: center;
            background-size: cover;
            scroll-snap-align: start;
            scroll-snap-stop: normal;
            @media (min-width: $screen-lg) {
              width: 600px;
            }
        }
    }
  }
  .swipe-cta {
      padding: 1rem 1.6rem 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img { margin-left: 1.2rem; }
  }
}