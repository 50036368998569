.blog {
  &__article-wrapper {
    &--detail {
      margin-top: 82px;
      background: transparent linear-gradient(0deg, #F7F7F7 0%, #F0F0F0 100%) 0% 0% no-repeat padding-box;
      padding: 52px 5% 0;
      @media (min-width: $screen-md) {
        margin: 82px 20px 0;
      }
    }
  }
  &__article-container {
    @media (min-width: $screen-md) {
      max-width: 768px;
    }
    @media (min-width: $screen-lg) {
      column-gap: 2%;
      max-width: 1430px;
    }
  }
  &__article-item {
    @media (min-width: $screen-lg) {
      width: calc(25% - 2%);
    }
  }
  &__link-container {
    padding: 16px;
    @media (min-width: $screen-md) {
      padding: 0;
    }
  }
  &__card {
    background-color: transparent;
    padding: 0 16px;
    border-radius: 8px;
    @media (min-width: $screen-lg) {
      background-color: transparent;
      border-radius: 0;
      padding: 0;
    }
    @media (min-width: $screen-lg) {
      padding-bottom: 48px;
    }
  }
  &__card-contents {
    border-radius: 8px;
    padding: 16px;
    @media (min-width: $screen-lg) {
      padding: 0;
    }
    &--highlight {
      background-color: white;
      @media (min-width: $screen-lg) {
        height: 360px;
        background-color: white;
        border-radius: 10px;
        padding-left: 22px;
      }
    }
    &--detail {
      @media (min-width: $screen-lg) {
        max-width: 56rem;
        margin: 0 auto;
      }
    }
  }
  &__card-image {
    border: solid 1px $bright-orange;
    border-radius: 8px;
    &--highlight {
      @media (min-width: $screen-lg) {
        width: 492px;
        height: 312px;
        max-width: none;
      }
    }
  }
  &__body {
    &--highlight {
      font-size: 16px;
    }
  }
  &__text {
    &--highlight {
      @media (min-width: $screen-lg) {
        padding: 0 52px;
      }
    }
  }
  &__para {
    &--non-detail {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow-y: hidden;
      @media (min-width: $screen-lg) {
        line-height: 20px;
      }
      @media (min-width: $screen-xl) {
        -webkit-line-clamp: 6;
      }
      
    }
  }
}