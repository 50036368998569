.video-carousel {
  padding: 40px 0 60px;
  @media (min-width: $screen-lg) {
    padding: 75px 0 96px;
  }
  h2 {
    padding: 0 26px;
    font-weight: $weight-bold;
    text-align: center;
    letter-spacing: -0.09px;
  }
  &__title {
    margin-bottom: 26px;
    @media (min-width: $screen-lg) {
      margin-bottom: 50px;
    }
  }
  &__scroll-pane {
    display: flex;
    column-gap: 14px;
    overflow-x: scroll;
    padding: 0 20px 20px;
    @media (min-width: $screen-lg) {
      padding: 0 70px 20px;
      column-gap: 32px;
    }
  }
  &__lozenge {
    flex: 0 0 auto;
    width: 305px;
    height: 430px;
    border: solid 1px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: $screen-lg) {
      display: block;
      width: 540px;
      height: 360px;
      padding: 12px;
    }
  }
  &__poster {
    position: relative;
    width: 270px;
    height: 164px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.3));
    &:hover {
      .video-carousel__control {
        filter: brightness(0.8);
      }
    }
    @media (min-width: $screen-lg) {
      width: 100%;
      height: 100%;
    }
  }
  &__control {
    width: 42px;
    height: 42px;
    @media (min-width: $screen-lg) {
      width: 100px;
      height: 100px;
    }
  }
  &__overlay {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 24px 20px;
    color: white;
    @media (min-width: $screen-lg) {
      display: flex;
      align-items: center;
    }
  }
  &__thumb {
    width: 84px;
    height: 84px;
    background-size: cover;
    border-radius: 42px;
    margin-right: 16px;
  }
}